import React, { useState } from 'react';
import { Col, Container, Form, ResponsiveEmbed, Row } from 'react-bootstrap';
import { graphql, Link } from 'gatsby';
import { Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { orderBy } from 'lodash';
import { renderAbstract } from '@utils';
import '@scss/high-school-page.scss';

const HighSchoolsPage = ({ data }) => {
  const { allHighSchoolProjects } = data.postgres;
  const [ selectedHighSchool, setSelectedHighSchool ] = useState(`All`);
  const highSchools = [ `All`, ...new Set(allHighSchoolProjects?.map(s => s.school.name)) ];

  const renderHighSchoolProjBlocks = () => orderBy(allHighSchoolProjects, `title`)
    .filter(h => selectedHighSchool !== `All` ? h.school.name === selectedHighSchool : true)
    ?.map(item =>
      <Col md={6} lg={3} key={item.id}>
        <div className="project">
          <div className="name">
            <h2>{item.title}</h2>
          </div>
          <div className="project-overlay">
            <Link to={`/${item.year}/high-school/${item.id}`}>
              <p>
                { renderAbstract(item.abstract) }
              </p>
            </Link>
          </div>
        </div>
      </Col>);

  return <Layout className="high-school-page">
    <Navbar year={allHighSchoolProjects[0].year} />
    <PageHeader
      title="High School Projects"
      titleTransform="uppercase"
      subTextComponent={<Container>
        <Row className="align-items-center">
          <Col style={{ padding: `10px` }} md={{ offset: 4, span: 4 }}>
            <Form.Label className="text-white">Select High School:</Form.Label>
            <Form.Control as="select" value={selectedHighSchool} onChange={(e) =>
              setSelectedHighSchool(e.target.value === selectedHighSchool ? null : e.target.value)}>
              {highSchools.map(category =>
                <option key={category} value={category}>{category}</option>)}
            </Form.Control>
          </Col>
        </Row>
      </Container>}
    />

    <Container className="video-holders-div">
      <Row className="justify-content-center">
        <Col md={6}>
          <div className="video-holder-title">How-To Teachers</div>
          <ResponsiveEmbed aspectRatio="16by9" className="ml-md-2 mb-3">
            <iframe
              title="IT Expo - HS Competition - How-To Teachers"
              src={`https://player.vimeo.com/video/914802762?h=78f2c69bb5`}
              className="embed-responsive-item"
              allow="autoplay; fullscreen"
              allowFullScreen />
          </ResponsiveEmbed>
        </Col>
        <Col md={6}>
          <div className="video-holder-title">How-To Students</div>
          <ResponsiveEmbed aspectRatio="16by9" className="ml-md-2 mb-3">
            <iframe
              title="IT Expo - HS Competition - How-To Students"
              src={`https://player.vimeo.com/video/914802818?h=070e2e6200`}
              className="embed-responsive-item"
              allow="autoplay; fullscreen"
              allowFullScreen />
          </ResponsiveEmbed>
        </Col>
      </Row>
    </Container>

    <Container className="projects-div">
      <Row>
        {renderHighSchoolProjBlocks()}
      </Row>
    </Container>

    <Sponsor year={allHighSchoolProjects[0].year} />
    <Footer />

  </Layout>;
};

export const query = graphql`
  query HighSchoolProjectsList($year: String!) {
    postgres {
      allHighSchoolProjects: allHighSchoolProjectsList(condition: { year: $year }) {
        id
        school: highSchoolByHighSchoolId {
          name
        }
        title
        abstract
        year
      }
    }
  }
`;

export default HighSchoolsPage;
